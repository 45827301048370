import { HttpClient, HttpHeaders, HttpParams, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Turnir } from '../common/models/Turnir';
import { CustomHttpParamEncoder } from '../common/utils/custom-encoder';
import { ConfigService } from './config.service';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AppStateService, Loader } from './app-state.service';
import { JSONUtils } from '../common/utils/json/json';
import { TurnirListResponse } from '../common/models/TurnirListResponse';
import { Location } from '@angular/common';
import { DatesService } from './dates.service';
import { Organization, OrganizationType, SportsmenInTurnir } from '../common/models/Organization';
import { ListResponse } from '../common/models/General';
import { Region } from '../common/models/Location';
import { Article } from '../common/models/Article';
import { Review, ReviewState } from '../common/models/Review';
import { TurnirResponse } from '../common/models/TurnirResponse';
import { OrganizationResponse } from '../common/models/OrganizationResponse';
import { Advert } from '../common/models/Advert';
import { User } from '../common/models/User';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  isLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private configService: ConfigService,
    private appState: AppStateService,
    private httpClient: HttpClient,
    private location: Location,
    private datesService: DatesService,
  ) {}

  private static getHttpParams(filters: Object): HttpParams {
    let queryParams = new HttpParams();

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        queryParams = queryParams.append(key, value);
      }
    }

    return queryParams;
  }

  public getTurnirList(params: Object): Observable<TurnirListResponse> {
    return this.sendToAPI('get', 'Turnir/list', params).pipe(
      map((resp: any) => {
        this.formatDateToRus(resp.result.list, ['start_date', 'end_date']);
        resp.result.list.forEach((e: any) => {
          e.filesExists = false;

          if (e.turnir_json_new) {
            const o = JSON.parse(e.turnir_json_new);
            e.filesExists = (o.files_as_images && o.files_as_images.length > 0) || (o.files && o.files.length > 0);
          }

          return e;
        });
        return resp.result;
      }),
    );
  }

  public getSportList(params: Object): Observable<TurnirListResponse> {
    return this.sendToAPI('post', 'Sport/list', params).pipe(
      map((resp: any) => {
        return resp.result;
      }),
    );
  }

  public getTurnirTypeList(params: Object): Observable<TurnirListResponse> {
    return this.sendToAPI('post', 'TurnirType/list', params).pipe(
      map((resp: any) => {
        return resp.result;
      }),
    );
  }

  public getOrganizationList(params: Object): Observable<ListResponse<Organization>> {
    return this.sendToAPI('get', 'Organization/list', params).pipe(
      map((resp: any) => {
        return resp.result;
      }),
    );
  }

  public getLocationList(params: Object): Observable<ListResponse<Region>> {
    return this.sendToAPI('post', 'Location/list', params).pipe(map((resp: any) => resp.result));
  }

  public getOrganizationTypeList(params: Object): Observable<ListResponse<OrganizationType>> {
    return this.sendToAPI('post', 'OrganizationType/list', params).pipe(map((resp: any) => resp.result));
  }

  public getOrganization(id: number): Observable<Organization> {
    return this.sendToAPI('post', 'Organization/get', { id }).pipe(
      map(response => {
        let ret: Organization = response.result;
        ret.is_published = !!ret.is_published;
        if (!ret.options) {
          ret.options = {};
        }
        return ret;
      }));
  }

  public updateOrganization(organization: Organization): Observable<any> {
    const o = { ...organization } as any;
    delete o['organization_images'];
    return this.sendToAPI('post', 'Organization/update', o as Organization);
  }

  public getArticleTypeList(params: Object): Observable<ListResponse<OrganizationType>> {
    return this.sendToAPI('post', 'ArticleType/list', params).pipe(map((resp: any) => resp.result));
  }

  public getArticleList(params: Object): Observable<ListResponse<Article>> {
    return this.sendToAPI('post', 'Article/list', params).pipe(
      map((resp: any) => {
        return resp.result;
      }),
    );
  }

  public getArticle(id: number): Observable<any> {
    return this.sendToAPI('post', 'Article/get', { id }).pipe(
      map((response: any) => {
        const advert = response.result;
        advert.is_published = !!advert.is_published;
        return advert;
      })
    );
  }

  public updateArticle(article: any): Observable<any> {
    if (article['published_at']) {
      article['published_at'] = this.datesService.dateToServerFormat(article['published_at']);
    }

    return this.sendToAPI('post', 'Article/update', { ...article });
  }

  public getAdvertList(params: Object): Observable<ListResponse<Advert>> {
    return this.sendToAPI('post', 'Advert/list', params).pipe(
      map((resp: any) => {
        return resp.result;
      }),
    );
  }

  public getAdvert(id: number): Observable<any> {
    return this.sendToAPI('post', 'Advert/get', { id }).pipe(
      map((response: any) => {
        const advert = response.result;
        advert.is_published = !!advert.is_published;
        return advert;
      })
    );
  }

  public updateAdvert(advert: any): Observable<any> {
    if (advert['start_date']) {
      advert['start_date'] = this.datesService.dateToServerFormat(advert['start_date']);
    }

    if (advert['end_date']) {
      advert['end_date'] = this.datesService.dateToServerFormat(advert['end_date']);
    }

    if (advert['published_at']) {
      advert['published_at'] = this.datesService.dateToServerFormat(advert['published_at']);
    }

    return this.sendToAPI('post', 'Advert/update', { ...advert });
  }

  public getReviewList(params: Object): Observable<ListResponse<Review>> {
    return this.sendToAPI('post', 'Review/list', params).pipe(
      map((resp: any) => {
        this.formatDateToRus(resp.result.list, ['updated_at']);
        return resp.result;
      }),
    );
  }

  public getReview(id: number): Observable<any> {
    return this.sendToAPI('post', 'Review/get', { id }).pipe(
      map((response: any) => {
        console.log(response);
        response.result.updated_at = new Date(response.result.updated_at);
        return response.result;
      })
    );
  }

  public updateReview(review: any): Observable<any> {
    if (review['published_at']) {
       review['published_at'] = this.datesService.dateToServerFormat(review['published_at']);
    }

    return this.sendToAPI('post', 'Review/update', { ...review });
  }

  public confirmReview(review: Review, temporary: boolean = false): Observable<any> {
    return this.sendToAPI('post', 'Review/confirm', { id: review.id, temporary });
  }

  public rejectReview(review: Review): Observable<any> {
    return this.sendToAPI('post', 'Review/reject', { id: review.id });
  }

  public getReviewStateList(params: Object): Observable<ListResponse<ReviewState>> {
    return this.sendToAPI('post', 'ReviewState/list', params).pipe(map((resp: any) => resp.result));
  }

  public getReviewRejectReasonList(params: Object): Observable<ListResponse<ReviewState>> {
    return this.sendToAPI('post', 'ReviewRejectReason/list', params).pipe(map((resp: any) => resp.result));
  }

  public getTurnir(id: number): Observable<TurnirResponse> {
    return this.sendToAPI('post', 'Turnir/get', { id }).pipe(
      map((resp: any) => {
        const turnir = resp.result;
        turnir.is_published = !!turnir.is_published;
        turnir.skip = !!turnir.skip;
        turnir.cancelled = !!turnir.cancelled;
        turnir.status_parse = !!turnir.status_parse;
        turnir.is_auto_published = !!turnir.is_auto_published;

        if (!turnir.options) {
          turnir.options = {
            files: {},
          };
        }

        if (!turnir.options.registration || Array.isArray(turnir.options.registration)) {
          turnir.options.registration = {};
        }

        if (!turnir.options.files || Array.isArray(turnir.options.files)) {
          turnir.options.files = {};
        }

        if (!turnir.options.decor || Array.isArray(turnir.options.decor)) {
          turnir.options.decor = {};
        }

        if (!turnir.options.partner || Array.isArray(turnir.options.partner)) {
          turnir.options.partner = {};
        }

        if (!turnir.options.weights) {
          turnir.options.weights = [];
        }

        if (turnir.turnir_json_new) {
          turnir.turnir_json_new = JSON.parse(turnir.turnir_json_new);
        }

        if (turnir.turnir_json) {
          turnir.turnir_json = JSON.parse(turnir.turnir_json);
        }
        return turnir;
      }),
    );
  }

  public getUser(id: number): Observable<any> {
    return this.sendToAPI('post', 'User/get', { id }).pipe(
      map((response: any) => {
        response.result.updated_at = new Date(response.result.updated_at);
        return response.result;
      })
    );
  }

  public saveTurnir(turnir: any) {
    if (turnir['start_date']) {
      turnir['start_date'] = this.datesService.dateToServerFormat(turnir['start_date']);
    }

    if (turnir['end_date']) {
      turnir['end_date'] = this.datesService.dateToServerFormat(turnir['end_date']);
    }

    if (turnir['options']) {
      const options = turnir['options'];

      if (options.postponedDate) {
        options.postponedDate = this.datesService.dateToServerFormat(options.postponedDate);
      }

      if (options['registration']) {
        const reg = options['registration'];

        if (reg['endDate']) {
          reg['endDate'] = this.datesService.dateToServerFormat(reg['endDate']);
        }
      }
    }
    return this.sendToAPI('post', 'Turnir/update', turnir);
  }

  public publishTurnir(id: number) {
    return this.sendToAPI('post', 'Turnir/publish', { id });
  }

  public login(login: string, password: string) {
    return this.sendToAPI('post-urlencoded', 'login1', {
      email: login,
      password: password,
    });
  }

  public testSession() {
    return this.sendToAPI('get', 'testSession', {});
  }

  public logout() {
    return this.sendToAPI('post', 'logout', {});
  }

  public getReviewsCountOnReview() {
    return this.sendToAPI('post', 'Review/countForReview', {});
  }

  delete(entity: string, id: number) {
    return this.sendToAPI('post', `${entity}/delete`, { id });
  }

  sendToAPI<T>(method: string, url: string, dataSrc: any = null, background = false): Observable<T | any> {
    this.isLoading.next(true);
    let loader: Loader;
    if (!background) {
      loader = this.appState.getLoader();
    }

    const data: any = this.prepareParams(dataSrc);
    const options: any = {
      withCredentials: true,
    };

    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    Object.keys(data).forEach(key => {
      httpParams = httpParams.append(key, data[key]);
    });

    switch (method) {
      case 'get':
        return this.httpClient
          .get<T>(
            this.configService.config.url + url,
            Object.assign(
              {
                params: httpParams,
              },
              options,
            ),
          )
          .pipe(
            catchError<any, any>((err, source) => this.catchHandler(err, source, loader)),
            finalize<any>(() => {
              this.finallyHandler(loader);
              this.isLoading.next(false);
            }),
          );
      case 'put':
        options['headers'] = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        return this.httpClient.put<T>(this.configService.config.url + url, data, options).pipe(
          catchError<any, any>((err, source) => this.catchHandler(err, source, loader)),
          finalize<any>(() => {
            this.finallyHandler(loader);
            this.isLoading.next(false);
          }),
        );
      case 'post':
        options['headers'] = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        return this.httpClient.post<T>(this.configService.config.url + url, data, options).pipe(
          catchError<any, any>((err, source) => this.catchHandler(err, source, loader)),
          finalize<T>(() => {
            this.finallyHandler(loader);
            this.isLoading.next(false);
          }),
        );
      case 'post-urlencoded':
        return this.httpClient
          .get(this.configService.config.host + '/sanctum/csrf-cookie', {
            withCredentials: true,
          })
          .pipe(
            mergeMap(_ => {
              return this.httpClient
                .post<T>(this.configService.config.url + url, httpParams, {
                  withCredentials: true,
                })
                .pipe(
                  catchError<any, any>((err, source) => this.catchHandler(err, source, loader)),
                  finalize<T>(() => {
                    this.finallyHandler(loader);
                    this.isLoading.next(false);
                  }),
                );
            }),
            // options['headers'] = new HttpHeaders({
            //   'Content-Type': 'application/json',
            // });
            // this.httpClient.post<T>(this.configService.url + url, data, options).pipe(
            //   catchError<any, any>((err, source) => this.catchHandler(err, source, loader)),
            //   finalize<T>(() => {
            //   this.finallyHandler(loader);
            //   this.isLoading.next(false);
            // }),
            // );
          );

      case 'delete':
        options['headers'] = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        return this.httpClient.delete(this.configService.config.url + url, options).pipe(
          catchError((err, source) => this.catchHandler(err, source, loader)),
          finalize(() => {
            this.finallyHandler(loader);
            this.isLoading.next(false);
          }),
        );
      case 'postUpdate':
        options['headers'] = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        return this.httpClient.post<T>(this.configService.config.url + url, data, options).pipe(
          catchError<any, any>((err, source) => this.catchHandler(err, source, loader)),
          finalize<T>(() => {
            this.finallyHandler(loader);
            this.isLoading.next(false);
          }),
        );
      case 'multipart': {
        // debugger;
        // options['headers'] = new HttpHeaders({
        //   'Content-Type': 'multipart/form-data',
        // });
        // return this.httpClient.post<T>(this.configService.config.url + url, data, options).pipe(
        //   catchError<any, any>((err, source) => this.catchHandler(err, source, loader)),
        //   finalize<T>(() => {
        //   this.finallyHandler(loader);
        //   this.isLoading.next(false);
        // }),
        // );
        // const formData = new FormData();
        // formData.append('file', 'xxx');

        return this.httpClient.post<any>(this.configService.config.url + url, dataSrc, options).pipe(
          catchError<any, any>((err, source) => {
            this.catchHandler(err, source, loader)
          }),
          finalize<T>(() => {
            this.finallyHandler(loader);
            this.isLoading.next(false);
          }),
        );
      }
    }
    return of({});
  }

  private prepareParams(data: Object): Object {
    if (data) {
      data = JSONUtils.jsonClone(data);
      this._parseDates(data);
    }
    return data;
  }

  catchHandler(err: any, source: any, loader: Loader) {
    if (loader) {
      loader.setProgress(100);
    }
    if (err.status === 401 || (err.status === 500 && err.error && err.error.error && err.error.error.code === 100)) {
      if (window.location.pathname.indexOf('/login') === -1 /*&& !this.configService.preproduction*/) {
        window.location.href = 'login';
      }
    }
    return throwError(err);
  }

  finallyHandler(loader: Loader) {
    if (loader) {
      loader.setProgress(100);
    }
  }

  _parseDates(data: Object) {
    /*if (data['startDate']) {
      data['startDate'] = this.parseDate(data['startDate'], 'from').format(this.parseDateService.outFormat);
    }
    if (data['endDate']) {
      data['endDate'] = this.parseDate(data['endDate'], 'to').format(this.parseDateService.outFormat);
    }
    if (data['params'] && data['params']['startDate']) {
      data['params']['startDate'] = this.parseDate(data['params']['startDate'], 'from').format(this.parseDateService.outFormat);
    }
    if (data['params'] && data['params']['endDate']) {
      data['params']['endDate'] = this.parseDate(data['params']['endDate'], 'to').format(this.parseDateService.outFormat);
    }*/
  }

  downloadAndConvertToPdf(files: Array<String>, turnirId: number) {
    return this.sendToAPI('get', 'Uploader/convertImagesToPdf', {
      files: files ? files.join(',') : '',
      turnirId,
    });
    // http://localhost:3000/kvas/admin/Uploader/convertImagesToPdf?files=https://www.judo-sambo.com/_fr/35/8022583.jpg,https://www.judo-sambo.com/_fr/35/7552623.jpg&turnirId=401
  }

  uploadTurnirFile(file: File, turnirId: number, discriminator = 'POLOZHENIE') {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('turnirId', turnirId.toString());
    formData.append('discriminator', discriminator);
    return this.sendToAPI('multipart', 'Uploader/uploadTurnirFile', formData);
  }

  uploadAdvertFile(file: File, advertId: number, discriminator = 'POLOZHENIE') {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('advertId', advertId.toString());
    formData.append('discriminator', discriminator);
    return this.sendToAPI('multipart', 'Uploader/uploadAdvertFile', formData);
  }

  uploadOrganizationFile(file: File, organizationId: number, discriminator = 'POLOZHENIE') {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('organizationId', organizationId.toString());
    formData.append('discriminator', discriminator);
    return this.sendToAPI('multipart', 'Uploader/uploadOrganizationFile', formData);
  }

  uploadTurnirFromRemote(url: string, turnirId: number) {
    return this.sendToAPI('get', 'Uploader/uploadTurnirFromRemote', { url, turnirId });
  }

  private formatDateToRus(arr: any, fields: string[]): any[] {
    return arr.map((el: any) => {
      fields.forEach((field: string) => {
        if (el[field]) {
          el[field] = Intl.DateTimeFormat('ru').format(new Date(el[field]));
        } else {
          el[field] = "";
        }
      });
    });
  }

  public getAllApplicationsForTurnir(turnirId: number): Observable<Array<Object>> {
    return this.sendToAPI('post', 'Application/getAllApplicationsForTurnir', {turnirId});
  }

  public confirmApplication(turnirId: number, applicationId: number): Observable<Array<Object>> {
    return this.sendToAPI('post', 'Application/confirmApplication', {turnirId, applicationId});
  }

  public rejectApplication(turnirId: number, applicationId: number): Observable<Array<Object>> {
    return this.sendToAPI('post', 'Application/rejectApplication', {turnirId, applicationId});
  }

  public getAllRegisteredSportsmensForTurnir(turnirId: number): Observable<Array<Object>> {
    return this.sendToAPI('post', 'Turnir/getAllRegisteredSportsmensForTurnir', {turnirId});
  }

  public getTurnirSportsmensFullList(turnirId: number): Observable<Array<Object>> {
    return this.sendToAPI('get', 'Turnir/getTurnirSportsmensFullList', {id: turnirId});
  }

  public getPreliminaryWeightningProtocolsJson(turnirId: number): Observable<Array<Object>> {
    return this.sendToAPI('get', 'Turnir/getPreliminaryWeightningProtocolsJson', {id: turnirId});
  }

  public getTurnirRegistrationStat(turnirId: number): Observable<Array<Object>> {
    return this.sendToAPI('get', 'Turnir/getTurnirRegistrationStat', {id: turnirId});
  }

  private static capitalizeFirstLetter(s: string) {
    if (!s) {
      return s;
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  public saveSportsmenInTurnir(sit: SportsmenInTurnir) {
    if (!sit.id) {
      sit.firstname = NetworkService.capitalizeFirstLetter(sit.firstname);
      sit.lastname = NetworkService.capitalizeFirstLetter(sit.lastname);
      sit.middlename = NetworkService.capitalizeFirstLetter(sit.middlename);
    }
    return this.sendToAPI('post', 'Weight/addSportsmenInTurnir', sit)
  }

  public deleteSportsmenInTurnir(sit: SportsmenInTurnir) {
    return this.sendToAPI('post', 'Weight/deleteSportsmenInTurnir', sit)
  }

  public loadSportsmenInTurnirList(turnir_id: number) {
    return this.sendToAPI('get', 'Weight/listSportsmenInTurnir', {turnir_id});
  }

  public turnirOrganizer_getTurnirStatus(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/getStatus', {turnir_id});
  }

  public turnirOrganizer_startWeightning(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/startWeightning', {turnir_id});
  }

  public turnirOrganizer_stopWeightning(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/stopWeightning', {turnir_id});
  }

  public turnirOrganizer_getWeightningProtocols(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/weightningProtocols', {turnir_id});
  }

  public turnirOrganizer_startGrouping(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/startGrouping', {turnir_id});
  }

  public turnirOrganizer_stopGrouping(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/stopGrouping', {turnir_id});
  }

  public turnirOrganizer_getGroupingData(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/getGroupingData', {turnir_id});
  }

  public turnirOrganizer_updateGroupingData(turnir_id: number, data: Object) {
    return this.sendToAPI('post', 'TurnirOrganizer/updateGroupingData', {turnir_id, data});
  }

  public turnirOrganizer_deleteWeightGroup(turnir_id: number, weight_group_id: Object) {
    return this.sendToAPI('post', 'TurnirOrganizer/deleteWeightGroup', {turnir_id, weight_group_id});
  }
  
  public turnirOrganizer_generateProtocols(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/generateProtocols', {turnir_id});
  }

  public turnirOrganizer_getProtocols(turnir_id: number) {
    return this.sendToAPI('get', 'TurnirOrganizer/getProtocols', {turnir_id});
  }

  public deleteOrganizationImage(imageId: number) {
    return this.sendToAPI('post', 'OrganizationImage/delete', {id: imageId });
  }

  public getUserList(params: Object): Observable<ListResponse<User>> {
    return this.sendToAPI('post', 'User/list', params).pipe(
      map((resp: any) => {
        this.formatDateToRus(resp.result.list, ['email_verified_at']);
        return resp.result;
      }),
    );
  }
/*
  public getUser(id: number): Observable<any> {
    return this.sendToAPI('post', 'Review/get', { id }).pipe(
      map((response: any) => {
        console.log(response);
        response.result.updated_at = new Date(response.result.updated_at);
        return response.result;
      })
    );
  }

  public updateUser(review: any): Observable<any> {
    if (review['published_at']) {
       review['published_at'] = this.datesService.dateToServerFormat(review['published_at']);
    }

    return this.sendToAPI('post', 'Review/update', { ...review });
  }*/
}
