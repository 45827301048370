import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'turnirs',
    loadChildren: () => import('./turnir/turnir.module').then(m => m.TurnirModule),
  },
  {
    path: 'organizations',
    loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
  },
  {
    path: 'articles',
    loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
  },
  {
    path: 'reviews',
    loadChildren: () => import('./review/review.module').then(m => m.ReviewModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
  },
  {
    path: 'advert',
    loadChildren: () => import('./advert/advert.module').then(m => m.AdvertModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
