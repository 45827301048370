import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  items: MenuItem[] = [];

  _itemsAdmin: MenuItem[] = [
    {
      label: 'Турниры',
      routerLink: ['/turnirs'],
      routerLinkActiveOptions: { exact: false },
    },
    {
      label: 'Организации',
      routerLink: ['/organizations'],
      routerLinkActiveOptions: { exact: false },
    },
    {
      label: 'Статьи',
      routerLink: ['/articles'],
      routerLinkActiveOptions: { exact: false },
    },
    {
      label: 'Отзывы',
      id: 'reviews',
      routerLink: ['/reviews'],
      routerLinkActiveOptions: { exact: false },
    },
    {
      label: 'Реклама',
      id: 'advert',
      routerLink: ['/advert'],
      routerLinkActiveOptions: { exact: false },
    },
    {
      label: 'Пользователи',
      id: 'users',
      routerLink: ['/users'],
      routerLinkActiveOptions: { exact: false },
    },
  ];

  _itemsOrganizator: MenuItem[] = [
    {
      label: 'Турниры',
      routerLink: ['/turnirs'],
      routerLinkActiveOptions: { exact: false },
    }
  ];

  constructor(private loginService: LoginService,
    private networkService: NetworkService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.loginService.roleCode === 1) {
      this.items = [...this._itemsAdmin];
      
      this.networkService.getReviewsCountOnReview()
      .subscribe(res => {
        if (res.result > 0) {
          const newitems = [...this._itemsAdmin];
          
          newitems.forEach(mi => {
            
            if (mi.id === 'reviews') {
              mi.label += ' (' + res.result + ')';
            }
          });

          this.items = newitems;
        }
        this.cdr.markForCheck();
      });
    } else if (this.loginService.roleCode === 2) {
      this.items = this._itemsOrganizator;
    } else {
      this.items = [];
    }
  }
}
