<div>
  <div class="fluid grid">
    <div class="field col-12 md:col-6 lg:col-2">
      <div class="flex flex-column">
        <label class="pb-2">Пропуск в адм</label>
        <p-dropdown
          class="w-full"
          [options]="skipOptions"
          [(ngModel)]="filters.skip"
          placeholder="Пропускать в админке"
          optionLabel="name"
          optionValue="code"
          [showClear]="true"
          (onChange)="onFiltersChanged()"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="field col-12 md:col-6 lg:col-2">
      <div class="flex flex-column">
        <label class="pb-2">Опубликован</label>
        <p-dropdown
          class="w-full"
          [options]="publishedOptions"
          [(ngModel)]="filters.is_published"
          placeholder="Опубликован"
          optionLabel="name"
          optionValue="code"
          [showClear]="true"
          (onChange)="onFiltersChanged()"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="field col-12 md:col-6 lg:col-2">
      <div class="flex flex-column">
        <label class="pb-2">Дата больше</label>
        <p-calendar class="w-full" [(ngModel)]="filters.date" (onSelect)="onFiltersChanged()"
          [showClear]="true"
          (onClear)="onFiltersChanged()"></p-calendar>
      </div>
    </div>

    <div class="field col-12 md:col-6 lg:col-3">
      <div class="flex flex-column">
        <label for="name" class="pb-2">Название</label>
        <span class="p-input-icon-right">
          <i class="pi pi-times" (click)="filters.name=''; onFiltersChanged();"></i>
          <input type="text" class="w-full" pInputText [(ngModel)]="filters.name" (change)="onFiltersChanged()" />
      </span>
      </div>
    </div>

    <div class="field col-12 md:col-6 lg:col-3">
      <div class="flex flex-column">
        <div class="pb-2">На автопубликации</div>
        <div>{{onAutopublishingCount}}</div>
      </div>
    </div>
  </div>

  <div class="flex justify-content-between align-items-center bg-white surface-border border-1 border-round-md">
    <p-paginator #paginator
      [first]="firstRowToShow"
      [rows]="rowsPerPage"
      [totalRecords]="totalCount"
      [rowsPerPageOptions]="rowsPerPageOptions"
      (onPageChange)="onPageChange($event)"
    >
    </p-paginator>
    <button
      pButton
      pRipple
      type="button"
      label="Добавить"
      class="p-button mr-2"
      icon="pi pi-plus"
      iconPos="right"
      (click)="addNew()"
    ></button>
  </div>

  <div class="bg-white p-3 mt-4 surface-border border-1 border-round-md">
    <p-table
      [columns]="cols"
      [value]="list"
      selectionMode="single"
      [(selection)]="selectedProduct2"
      dataKey="id"
      (onRowSelect)="onRowSelect($event)"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr class="table-row">
          <td *ngFor="let col of columns" [pSelectableRow]="rowData">
            <ng-container *ngIf="col.field === 'is_published'"> </ng-container>
            <div [ngSwitch]="col.field">
              <div *ngSwitchCase="'filesExists'">
                <div class="status-indicator" [ngClass]="rowData[col.field] ? 'active' : ''"></div>
              </div>
              <div *ngSwitchCase="'place_organization'">
                <small>{{ rowData?.place_organization?.name }}</small>
              </div>
              <div *ngSwitchCase="'turnirSource'">
                <small>{{ rowData?.__turnir_json?.__?.source }}</small>
              </div>
              <div *ngSwitchCase="'actions'">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-text delete-button"
                  (click)="deleteItem(rowData.id)"
                ></button>
              </div>
              <div *ngSwitchCase="'is_published'">
                <div class="status-indicator" [ngClass]="rowData[col.field] ? 'active' : ''">
                  {{ rowData["is_auto_published"] ? 'a' : '' }}
                </div>
              </div>
              <div *ngSwitchDefault>{{ rowData[col.field] }}</div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
