import { SportsmenInTurnirGroup } from "./Organization";

export interface Turnir {
    id: number;
    name: string;
    fullname: string;
    start_date: string;
    end_date: string;
    sport: {
        id: number;
        name: string;
    };
    __turnir_json: any;
    options: {
        registration: {
            enabled: boolean;
        }
    };
}

export class TurnirHelpers {
    static isRegistrationAllowed(turnir: Turnir): boolean {
        return turnir && turnir.options && turnir.options.registration &&  turnir.options.registration.enabled;
    }
}

export interface TurnirAge {
    min_year: number;
    max_year: number;
    weights: TurnirAgeWeight[];
}

export interface TurnirAgeWeight {
    name: string;
    sportsmens: SportsmenInTurnirGroup[];
    battles: Battle[];
}

export interface Battle {
    id: number;
    turnir_age_weight_id: number;
    sit1_id: number;
    sit2_id: number;
    lap: number;
    num: number;
}
